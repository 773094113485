import Vue from "vue";
import {
  faStore,
  faUser,
  faBullhorn,
  faSearch,
  faFileUpload,
  faChevronUp,
  faChevronDown,
  faFilter,
  faSortDown,
  faPencilAlt,
  faTrashAlt,
  faTimesCircle,
  faFileDownload,
  faBarcode,
  faPlus,
  faMinus,
  faStar,
  faChevronLeft,
  faChevronRight,
  faHome,
  faBuilding,
  faHotel,
  faCircle,
  faArrowRight,
  faArrowDown,
  faClipboard,
  faBox,
  faHandHoldingUsd,
  faEllipsisV,
  faFileInvoice,
  faCheck,
  faDownload,
  faTimes,
  faPercent,
  faGift,
  faCalendar,
  faCheckCircle,
  faExclamationCircle,
  faCopy,
  faPlusSquare,
  faSpinner,
  faChevronCircleUp,
  faFileExport,
  faChevronCircleDown,
  faPaste,
  faCalendarAlt,
  faCaretUp,
  faEdit,
  faBirthdayCake,
  faPhone,
  faEnvelope,
  faVenus,
  faMars,
  faCreditCard,
  faSave,
  faClock,
  faPaperPlane,
  faStickyNote,
  faFileImport,
  faFileExcel,
  faFileArchive,
  faLock,
  faInfoCircle,
  faInfo,
  faEye,
  faEyeSlash,
  faPalette,
  faLink,
  faUnlink,
  faPen,
  faCog,
  faCalendarCheck,
  faList,
  faWarehouse,
  faSlidersH,
  faChartLine,
  faMoneyBill,
  faShoppingBag,
  faRegistered,
  faFile,
  faTag,
  faTags,
  faPuzzlePiece,
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faStar as faStarFar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(
  faStore,
  faUser,
  faBullhorn,
  faSearch,
  faFilter,
  faFileUpload,
  faChevronUp,
  faChevronDown,
  faSortDown,
  faPencilAlt,
  faTrashAlt,
  faTimesCircle,
  faFileDownload,
  faEye,
  faBarcode,
  faPlus,
  faMinus,
  faStar,
  faStarFar,
  faChevronLeft,
  faChevronRight,
  faHome,
  faBuilding,
  faHotel,
  faCircle,
  faArrowRight,
  faArrowDown,
  faClipboard,
  faBox,
  faHandHoldingUsd,
  faEllipsisV,
  faFileInvoice,
  faCheck,
  faTimes,
  faPercent,
  faGift,
  faCalendar,
  faCheckCircle,
  faExclamationCircle,
  faCopy,
  faPlusSquare,
  faSpinner,
  faChevronCircleUp,
  faFileExport,
  faChevronCircleDown,
  faPaste,
  faCalendarAlt,
  faCaretUp,
  faEdit,
  faBirthdayCake,
  faPhone,
  faEnvelope,
  faVenus,
  faMars,
  faCreditCard,
  faSave,
  faClock,
  faPaperPlane,
  faStickyNote,
  faFileImport,
  faLock,
  faInfoCircle,
  faInfo,
  faEye,
  faEyeSlash,
  faPalette,
  faLink,
  faUnlink,
  faPen,
  faCog,
  faCalendarCheck,
  faList,
  faWarehouse,
  faSlidersH,
  faChartLine,
  faDownload,
  faFileArchive,
  faMoneyBill,
  faShoppingBag,
  faRegistered,
  faFile,
  faFileExcel,
  faTag,
  faTags,
  faPuzzlePiece
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
