import Vue from "vue";
import App from "./App";
import router from "./router";
import CoreuiVue from "@coreui/vue";
import "./main.css";
import { store } from "./store";
import { iconsSet as icons } from "./assets/icons/icons.js";

import VueCookies from "vue-cookies";

import Vuelidate from "vuelidate";
import VueMoment from "vue-moment";
import vueNumeralFilterInstaller from "vue-numeral-filter";
const moment = require("moment");
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "babel-polyfill";

// import { vue3Debounce } from 'vue-debounce'
import vueDebounce from "vue-debounce";
import "./plugins/components";
import swal from "sweetalert";
import swal2 from "sweetalert2";
import alert from "./plugins/alert";
import utility from "./plugins/utility";
import "./plugins/icon";

// import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import VueClipboard from "vue-clipboard2";
Vue.prototype.default_image = require("@/assets/img/default-placeholder.png");
Vue.prototype.handleImageSrc = (e) =>
  (e.target.src = require("@/assets/img/default-placeholder.png"));
VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";

Vue.config.devtools = true;
Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.use(vueNumeralFilterInstaller, { locale: "en-gb" });
Vue.use(VueCookies);
Vue.use(BootstrapVue);
Vue.use(vueDebounce);
Vue.use(IconsPlugin);
Vue.use(Vuelidate);
Vue.use({
  install(Vue) {
    Vue.swal = swal;
    Vue.swal2 = swal2;
    Vue.prototype.$swal = swal;
    Vue.prototype.$swal2 = swal2;
  },
});
Vue.use(VueMoment, {
  moment,
});
Vue.component("datetime", Datetime);

Vue.prototype.$formatDateNew = "DD/MM/YYYY";
Vue.prototype.$apiPath = process.env.VUE_APP_API;
Vue.prototype.$formatDate = "DD MMM YYYY";
Vue.prototype.$formatDateTime = "DD MMM YYYY (HH:mm)";
Vue.prototype.$formatTime = "HH:mm";
Vue.prototype.$EventBus = new Vue();

new Vue({
  el: "#app",

  router,
  store,
  alert,
  utility,
  icons,
  template: "<App/>",
  components: {
    App,
  },
});
