<template>
  <div>
    <label v-if="textFloat" class="main-label"> {{ textFloat }}</label>
    <multiselect
      :close-on-select="closeOnSelect"
      :clear-on-select="clearOnSelect"
      multiple
      :value="valueDisplay"
      @input="handleSelect"
      :options="options"
      :placeholder="placeholder"
      :track-by="valueField"
      :label="textField"
      @remove="(val) => $emit('remove', val)"
      @select="(val) => $emit('select', val)"
    >
      <template #noResult> <div>No value found.</div> </template>
      <template #noOptions> <div>No value found.</div> </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  components: { Multiselect },
  props: {
    options: { required: true, default: () => [] },
    placeholder: { required: false, default: "select or search value." },
    textField: { required: true },
    valueField: { required: true },
    value: { required: true },
    textFloat: { required: false },
    closeOnSelect: {
      required: false,
      default: false,
    },
    clearOnSelect: {
      required: false,
      default: false,
    },
    defaultValue: {
      required: false,
      default: false,
    },
  },

  computed: {
    valueDisplay() {
      if (this.defaultValue) {
        return this.value;
      }
      return this.options.filter((el) =>
        this.value.includes(el[this.valueField])
      );
    },
  },
  methods: {
    handleSelect(value) {
      if (this.defaultValue) {
        return this.$emit("input", value);
      }
      this.$emit(
        "input",
        value.map((el) => el[this.valueField])
      );
    },
  },
};
</script>

<style></style>
